import React, { useState, useEffect } from "react";
import axiosInstance from "../interceptor.js";
import endpoints from "../endpoint.js";
const ManageAlbums=()=>{
    const [albums, setAlbums] = useState([]);
    const [currentAlbum,setCurrentAlbum]=useState(null);
    const [modalType,setModalType]=useState(null);
    const[error,setError]=useState("");
    const [success,setSuccess]=useState("");
    const [imageModal,setImageModal]=useState(false);
    const [artists, setArtists] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [page, setPage] = useState(1); 
    const [limit, setLimit] = useState(10); // Items per page
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(()=>{
        fetchAlbums();
        
    },[page, limit]);


    const fetchAlbums = async () => {
        try {
            const response = await axiosInstance.post(endpoints.getAlbums, { page, limit });
    
            if (Array.isArray(response.data.albums)) {
                if (response.data.albums.length === 0) {
                    setSuccess("No albums available");
                } else {
                    setAlbums(response.data.albums);
                    setTotalPages(response.data.pagination?.totalPages || 0);
                }
            } else {
                setSuccess("Data is not available"); 
                setTimeout(() => setSuccess(''), 4000);
            }
        } catch (error) {
            console.error("Error fetching albums:", error);
            setError("Failed to fetch albums.");
            setTimeout(() => setError(""), 4000);
        }
    };
    
    const filtered = albums.filter(album =>
        album.title.toLowerCase().includes(searchQuery.toLowerCase())
       
    );
 


    const addAlbum = async () => {
        try {
            const response = await axiosInstance.post(endpoints.getArtist);
    
            if (response.data.artists && response.data.artists.length > 0) {
                setArtists(response.data.artists);
                setCurrentAlbum({ title: "", artist_id: "", released_date: "", imagefile: null });
                setModalType('add');
            } else {
                setShowAlert(true); 
                setTimeout(() => setShowAlert(false), 4000); 
            }
        } catch (error) {
            console.error('Error fetching artists:', error);
            setError("Failed to fetch artists.");
            setTimeout(() => setError(''), 4000);
        }
    };

    
    const editAlbum = async (album) => {
        try {
            // Fetch artists or other data needed for editing
            const response = await axiosInstance.post(endpoints.getArtist);
            if (response.data.artists) {
                setArtists(response.data.artists);
    
                // Set the current album with the provided album details
                setCurrentAlbum({
                    id:album.id,
                    title: album.title || "",
                    artist_id: album.artist_id || "",
                    released_date: album.released_date || "",
                    imagefile: album.imagefile || null, // Maintain existing image if applicable
                });
    
                // Open the modal in edit mode
                setModalType("edit");
            } else {
                setError("Unexpected response structure.");
                setTimeout(() => setError(''), 4000);
            }
        } catch (error) {
            console.error("Error fetching artists:", error);
            setError("Failed to fetch artists.");
            setTimeout(() => setError(''), 4000);
        }
    };
    
    const handleCreate = async () => {
        try {
            
            if (!currentAlbum.title || !currentAlbum.artist_id) {
                setError('All fields are required.');
                setTimeout(() => setError(''), 4000);
                return;
            }
    
            const formData = new FormData();
            formData.append('title', currentAlbum.title);
            formData.append('artist_id', currentAlbum.artist_id);
            formData.append('released_date', currentAlbum.released_date);
    
            if (currentAlbum.imagefile) {
                formData.append('imagefile', currentAlbum.imagefile);
            }
    
    
            await axiosInstance.post(endpoints.createAlbums, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
    
            setModalType(null);
            fetchAlbums();
            setSuccess('Successfully added the album.');
            setTimeout(() => setSuccess(''), 4000);
        } catch (error) {
            console.error('Error adding album:', error.response?.data || error.message);
            setError('Error adding album. Please try again.');
            setTimeout(() => setError(''), 4000);
        }
    };
    

    const handleUpdate = async () => {
        try {
            if (!currentAlbum.title || !currentAlbum.artist_id || !currentAlbum.released_date) {
                setError('All fields are required.');
                setTimeout(() => setError(''), 4000);
                return;
            }
    
            const formData = new FormData();
            formData.append('id', currentAlbum.id);
            formData.append('title', currentAlbum.title);
            formData.append('artist_id', currentAlbum.artist_id);
            formData.append('released_date', currentAlbum.released_date);
    
            if (currentAlbum.imagefile) {
                formData.append('imagefile', currentAlbum.imagefile);
            }
    
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
    
            await axiosInstance.post(endpoints.updateAlbums, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
    
            setModalType(null);
            fetchAlbums();
            setSuccess('Successfully updated the album.');
            setTimeout(() => setSuccess(''), 4000);
        } catch (error) {
            console.error('Error updating album:', error.response?.data || error.message);
            setError('Error updating album. Please try again.');
            setTimeout(() => setError(''), 4000);
        }
    };
    
    
    const handleSave = () => {
        if (modalType === 'add') {
            handleCreate();
        } else if (modalType === 'edit') {
            handleUpdate();
        }
    }; 
    const handleDelete = async () => {
        try {
            await axiosInstance.post(endpoints.deleteAlbums, { id: currentAlbum.id });
    
            setModalType(null);
            setAlbums([]); 
            fetchAlbums();
    
            setSuccess("Successfully Deleted the Album");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error deleting album:', error);
            setError("Error deleting album. Please try again.");
            setTimeout(() => setError(''), 4000);
        }
    };
    
    return (
        <div className="container">
            <h2>Manage Albums</h2>
            {showAlert && (
    <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>No artists available!</strong> Please create an artist before adding an album.
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}></button>
    </div>
)}

            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p> }
            <div className="d-flex justify-content-between align-items-center mb-3">

            <button type="button" className="btn btn-success col-2" style={{ marginBottom: '20px' }} onClick={addAlbum}>Add Album</button>


            <div className="input-group col-4">
        <input
            type="text"
            className="form-control"
            placeholder="Search Album..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
        />
        <span className="input-group-text">
        <i className="fas fa-search"></i>
        </span>
    </div>
</div>
           
            <div className="card shadow-sm">
  <div className="card-body">
    <div className="table-responsive">
      <table className="table table-bordered table-hover align-middle">
        <thead className="table-light">
          <tr>
            <th>Name</th>
            <th>Artist</th>
            <th>Released Date</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
                        {filtered.length > 0 ? (
                            filtered.map((album) => (
                                <tr key={album.id}>
                                    <td>{album.title}</td>
                                    <td>{album.artists?.name||"unknown Artist"}</td>
                                    <td>{album.released_date|| "Date not provided"}</td>
                                    <td>
                                        {album.image_url ? (
                                            <img
                                                src={album.image_url}
                                                alt={album.name || "Album Image"}
                                                width="100"
                                                height="100"
                                                style={{ borderRadius: "4px", objectFit: "cover" }}
                                                onClick={() => {
                                                    setCurrentAlbum(album);
                                                    setImageModal(true);
                                                }}
                                                onError={async (e) => {
                                                    console.error("Image failed to load:", album.image_url);
                                                    e.target.onerror = null;
                                                    // e.target.src = "https://via.placeholder.com/50";
                                                }}
                                            />
                                        ) : (
                                            "No Image"
                                        )}
                                    </td>



                                    <td>
              <button className="btn btn-sm me-1" onClick={() =>  {
                                            setCurrentAlbum(album);
                                            setModalType('view');
                                        }}>
                <i className="fas fa-eye"></i>
              </button>
              <button className="btn btn-sm me-1" onClick={() => editAlbum(album)}>
                <i className="fas fa-edit"></i>
              </button>
              <button className="btn btn-sm " onClick={() => {
                                            setCurrentAlbum(album);
                                            setModalType('delete');
                                        }}>
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No album found</td>
                            </tr>
                        )}
                    </tbody>

      </table>
    </div>
  </div>
</div>

            <div
    className="pagination"
    style={{
        display: "flex", // Use flexbox for alignment
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically if needed
        marginTop: "20px", // Optional spacing from other elements
    }}
>
    <button
        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        disabled={page === 1}
    >
        &laquo;
    </button>

    {Array.from({ length: totalPages }, (_, index) => {
        const pageNumber = index + 1;
        return (
            <button
                key={pageNumber}
                onClick={() => setPage(pageNumber)}
                className={page === pageNumber ? "active" : ""}
            >
                {pageNumber}
            </button>
        );
    })}

    <button
        onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={page === totalPages}
    >
        &raquo;
    </button>
</div>

{modalType && (
    <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="modal-dialog modal-md">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {modalType === 'view' ? 'Album Details' : modalType === 'add' ? 'Add New Album' : modalType === 'edit' ? 'Edit Album' : 'Confirm Deletion'}
                    </h5>
                    <button type="button" className="btn-close" onClick={() => setModalType(false)}></button>
                </div>
                <div className="modal-body">
                    {modalType === 'view' && currentAlbum && (
                        <>
                            <p><strong>Title:</strong> {currentAlbum.title}</p>
                            <p><strong>Artist:</strong> {currentAlbum.artists?.name || "Unknown Artist"}</p>
                            <p><strong>Released Date:</strong> {currentAlbum.released_date}</p>
                            <p><strong>Image:</strong> <img src={currentAlbum.image_url} alt={currentAlbum.name} width="100" /></p>
                            
                        </>
                    )}
                    {(modalType === 'add' || modalType === 'edit') && (
                        <>
                            <div className="mb-3">
                                <label htmlFor="albumTitle" className="form-label">Title</label>
                                <input
                                    id="albumTitle"
                                    type="text"
                                    className="form-control"
                                    placeholder="Title"
                                    value={currentAlbum.title}
                                    onChange={(e) => setCurrentAlbum({ ...currentAlbum, title: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="artistSelect" className="form-label">Artist</label>
                                <select
                                    id="artistSelect"
                                    className="form-select"
                                    value={currentAlbum.artist_id}
                                    onChange={(e) => setCurrentAlbum({ ...currentAlbum, artist_id: e.target.value })}
                                >
                                    <option value="" disabled>Select Artist</option>
                                    {artists.map((artist) => (
                                        <option key={artist.id} value={artist.id}>
                                            {artist.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="releaseDate" className="form-label">Released Date</label>
                                <input
                                    id="releaseDate"
                                    type="date"
                                    className="form-control"
                                    value={currentAlbum.released_date}
                                    onChange={(e) => setCurrentAlbum({ ...currentAlbum, released_date: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="albumImage" className="form-label">Image</label>
                                <input
                                    id="albumImage"
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => setCurrentAlbum({ ...currentAlbum, imagefile: e.target.files[0] })}
                                />
                            </div>
                        </>
                    )}
                    {modalType === 'delete' && (
                        <>
                            <p>Are you sure you want to delete <strong>{currentAlbum.name}</strong>?</p>
                        </>
                    )}
                </div>
                <div className="modal-footer">
                    {(modalType === 'add' || modalType === 'edit') && (
                        <>
                            <button type="button" className="btn btn-success" onClick={handleSave}>Save</button>
                            <button type="button" className="btn btn-danger" onClick={() => setModalType(null)}>Cancel</button>
                        </>
                    )}
                    {modalType === 'delete' && (
                        <>
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Yes, Delete</button>
                            <button type="button" className="btn btn-success" onClick={() => setModalType(null)}>Cancel</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    </div>
)}

{imageModal && currentAlbum && (
    <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                <strong>{currentAlbum.name}</strong>
                    <h5 className="modal-title">{currentAlbum.title}</h5>
                    <button type="button" className="btn-close" onClick={() => setImageModal(false)}></button>
                </div>
                <div className="modal-body text-center">
                    <img
                        src={currentAlbum.image_url}
                        alt={currentAlbum.name}
                        style={{ maxWidth: '100%', maxHeight: '50vh', objectFit: 'contain' }}
                    />
                </div>
            </div>
        </div>
    </div>
)}

            <style jsx>{`
  .container {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 250px;
    padding: 20px;
    width: 80%;
  }
  h2{
  font-weight: bold; /* Makes the headings bold */
  
    text-align: center;
    color: #4caf50;
  }
 
  
  .error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
   
  }
   .success{
  color:green;
  text-align:center;
  margin-bottom: 10px;
  }
  tr:hover {
    background-color: #ddd;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

 
  .btn-sm:hover,.icon-button:hover {
    color: #4caf50;
  }
   .btn-sm {
    background-color: transparent !important;
    border: none; /* Optional: Remove border if needed */
}


  
  .pagination {
  display: inline-block;
}

.pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin: 0 2px;
  background-color: white;
  
}

.pagination button.active {
  background-color: #4CAF50;
  color: white;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
}

.pagination button:disabled {
  color: gray;
  cursor: not-allowed;
}
.btn-success{
background-color: #4CAF50;
}


`}</style>



        </div>

    );


};
export default ManageAlbums
