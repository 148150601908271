import React, { useEffect } from "react";
import urls from "../endpoint.js";
import axios from "../interceptor.js";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaUserLarge } from "react-icons/fa6";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
const Navbar = () => {
	const navigate = useNavigate();
	const userDetails = JSON.parse(localStorage.getItem("userData"));
	const logout = () => {
		axios
			.post(urls.logout)
			.then((resp) => {
				localStorage.clear();
				navigate("/");
			})
			.catch((err) => {});
	};

	return (
		<div className="header dynamic-bgcolor" style={{ height: "6%" }}>
			<div className="logo logo-dark">
				<a>
					<img
						src={logo}
						style={{ height: "20%", width: "20%", marginTop: "1%" }}
						alt="Logo"
					/>
				
					<img
						className="logo-fold "
						src={logo}
			
						style={{ height: "60%", width: "60%", marginLeft: "25%"}}
						alt="Logo"
					/>
					
					
				</a>
			</div>
			<div className="nav-wrap">
				<ul className="nav-left">
					<li className="desktop-toggle">
						<a>
							<i className="anticon"></i>
						</a>
					</li>
					<li className="mobile-toggle">
						<a>
							<i className="anticon"></i>
						</a>
					</li>
				</ul>
				<ul className="nav-right">
					<li className="dropdown dropdown-animated scale-left">
						{/* <a href="#" data-toggle="dropdown">
                                <i className="anticon anticon-bell notification-badge"></i>
                            </a> */}
						<div className="dropdown-menu pop-notification">
							{/* <div className="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p className="text-dark font-weight-semibold m-b-0">
                                        <i className="anticon anticon-bell"></i>
                                        <span className="m-l-10">Notification</span>
                                    </p>
                                    <a className="btn-sm btn-default btn" href="#">
                                        <small>View All</small>
                                    </a>
                                </div> */}
						</div>
					</li>
					<li className="dropdown dropdown-animated scale-left">
						<div className="pointer" data-toggle="dropdown">
							<div className=" m-h-20 m-r-25">
								{/* <img src="assets/images/avatars/thumb-3.jpg"  alt=""/> */}
								{/* <FaUser /> */}
								<FaUserLarge />
							</div>
						</div>
						<div className="p-b-15 p-t-20 dropdown-menu pop-profile">
							<div className="p-h-20 p-b-15 m-b-10 border-bottom">
								<div className="d-flex m-r-50">
									<div>
										{/* <img src="assets/images/avatars/thumb-3.jpg" alt=""/> */}
										<FaUserLarge />
									</div>
									<div className="m-l-10">
										<p className="m-b-0 text-dark font-weight-semibold">
											{userDetails?.username}
										</p>
										<p className="m-b-0 opacity-07">
											{userDetails?.designation}
										</p>
									</div>
								</div>
							</div>
							<Link to={"/changepassword"} className="text-dark">
								<a
									className="dropdown-item d-block p-h-15 p-v-10"
									style={{ color: "black" }}
								>
									<div className="d-flex align-items-center justify-content-between">
										<div>
											<i className="anticon opacity-04 font-size-16 anticon-lock"></i>
											<span className="m-l-10">Change Password </span>
										</div>
									</div>
								</a>
							</Link>
							<Link to={"/login"} className="text-dark">
							<a
								className="dropdown-item d-block p-h-15 p-v-10"
								style={{ cursor: "pointer" }}
							>
								<div className="d-flex align-items-center justify-content-between">
									<div>
										<i className="anticon opacity-04 font-size-16 anticon-logout"></i>
										<span className="m-l-10" onClick={logout}>
											Logout
										</span>
									</div>
								</div>
							</a>
							</Link>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Navbar;
