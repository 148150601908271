import React, { useState, useEffect } from "react";
import axiosInstance from "../interceptor.js"; 
import endpoints from "../endpoint.js";
import { FaEye, FaEdit, FaTrashAlt } from 'react-icons/fa';

const ManageDirector = () => {
    const [Directors, setDirector] = useState([]);
    const [currentDirector, setcurrentDirector] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState('');
    const [page, setPage] = useState(1); // Current page
    const [limit, setLimit] = useState(10); // Items per page
    const [totalPages, setTotalPages] = useState(1); // Total pages
 const [searchQuery, setSearchQuery] = useState("");
    


    useEffect(() => {
        fetchDirector();
    }, [page,limit]);

    const fetchDirector = async () => {
        const payload = {
            page: page || 1,
            limit: limit || 10,
        };
        try {
            const response = await axiosInstance.post(endpoints.getDir,payload);
            console.log("API Response:", response.data); 
    
            if (response.data.success && Array.isArray(response.data.data)) {
                setDirector(response.data.data); 
                setTotalPages(response.data.pagination.totalPages);
                
            } else {
                setSuccess("Data not available");
                setTimeout(() => setSuccess(''), 4000);
            }
        } catch (error) {
            console.error("Error fetching directors:", error);
            setError("Failed to fetch directors.");
            setTimeout(() => setError(''), 4000);
        }
    };
    const filtered = Directors.filter(director =>
        director.name.toLowerCase().includes(searchQuery.toLowerCase())
       
    );
    

    const handleCreate = async () => {
        try {
    
            if (!currentDirector.name || currentDirector.name.trim() === '') {
                setError('Director name is required.');
                console.error('Validation Error: Director name is missing.');
                setTimeout(() => setError(''), 4000);
                return;
            }
    
            const payload = {
                name: currentDirector.name.trim(),
                bio:currentDirector.bio,
            };
    
            // if (currentDirector.bio && currentDirector.bio.trim() !== '') {
            //     payload.status = current.status.trim();
            // }
    
            console.log('Payload for Create:', payload);
    
            const response = await axiosInstance.post(endpoints.createDir, payload);
    
            console.log('Create Response:', response.data);
            setModalType(null);
            fetchDirector();
            setSuccess("Successfully Added the Director");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error creating Director:', error.response?.data || error.message);
            
    
            setError(
                error.response?.data?.message || 'Error creating Director. Please try again.'
            );
            setTimeout(() => setError(''), 4000);
        }
    };
    
    const handleUpdate = async () => {
        try {
            if (!currentDirector.name || currentDirector.name.trim() === '') {
                setError('Director name is required.');
                console.error('Validation Error: Director name is missing.');
                setTimeout(() => setError(''), 4000);
                return;
            }
    
            const payload = {
                id: currentDirector.id,
                name: currentDirector.name.trim(),
                bio:currentDirector.bio
            };
    
           
    
            console.log('Payload for Update:', payload); 
    
            const response = await axiosInstance.post(endpoints.updateDir, payload);
    
            console.log('Update Response:', response.data);
            setModalType(null);
            fetchDirector();
            setSuccess("Successfully Updated the Director");
    
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error updating Director:', error.response?.data || error.message);
            setError(
                error.response?.data?.message || 'Error updating Director. Please try again.'
            );
            setTimeout(() => setError(''), 4000);
        }
    };
    
    const handleSave = () => {
        if (modalType === 'add') {
            handleCreate();
        } else if (modalType === 'edit') {
            handleUpdate();
        }
    };    
    
    
    
    

    const handleDelete = async () => {
        try {
            await axiosInstance.post(endpoints.deleteDir, { id: currentDirector.id });
            setSuccess("Successfully Deleted the Director");
            setModalType(null);
            setDirector([]); 
            fetchDirector();
            setSuccess("Successfully Deleted the Director");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error deleting Director:', error);
            setError("Error deleting Director. Please try again.");
            setTimeout(() => setError(''), 4000);
        }
    };
    
   
    return (
        <div className="container">
        <h2>Manage Directors</h2>

        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p> }
        <div className="d-flex justify-content-between align-items-center mb-3">
        <button className="btn btn-success col-2" style={{ marginBottom: '20px' }}onClick={() => {
            setcurrentDirector({ name: "", bio: "" });
            setModalType('add');
        }}>Add Directors</button>

          {/* Search Input Field */}
    <div className="input-group col-4">
        <input
            type="text"
            className="form-control"
            placeholder="Search Director..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
        />
        <span className="input-group-text">
        <i className="fas fa-search"></i>
        </span>
    </div>
</div>

<div className="card shadow-sm">
  <div className="card-body">
    <div className="table-responsive">
      <table className="table table-bordered table-hover align-middle">
        <thead className="table-light">
          <tr>
            <th>Name</th>
            <th>Bio</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filtered.length > 0 ? (
            filtered.map((director) => (
              <tr key={director.id}>
                <td>{director.name}</td>
                <td>
                  {director.bio}
                </td>
                <td>
                  <button
                    className="btn btn-sm me-1"
                    onClick={() => {
                      setcurrentDirector(director);
                      setModalType("view");
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </button>
                  <button
                    className="btn btn-sm me-1"
                    onClick={() => {
                      setcurrentDirector(director);
                      setModalType("edit");
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-sm"
                    onClick={() => {
                      setcurrentDirector(director);
                      setModalType("delete");
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No Directorfound</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>


        
        <div
    className="pagination"
    style={{
        display: "flex", // Use flexbox for alignment
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically if needed
        marginTop: "20px", // Optional spacing from other elements
    }}
>
    <button
        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        disabled={page === 1}
    >
        &laquo;
    </button>

    {Array.from({ length: totalPages }, (_, index) => {
        const pageNumber = index + 1;
        return (
            <button
                key={pageNumber}
                onClick={() => setPage(pageNumber)}
                className={page === pageNumber ? "active" : ""}
            >
                {pageNumber}
            </button>
        );
    })}

    <button
        onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={page === totalPages}
    >
        &raquo;
    </button>
</div>

{modalType && (
    <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="modal-dialog modal-md">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {modalType === 'view' ? 'Director Details' : modalType === 'add' ? 'Add New Director' : modalType === 'edit' ? 'Edit Director' : 'Confirm Deletion'}
                    </h5>
                    <button type="button" className="btn-close" onClick={() => setModalType(false)}></button>
                </div>
                <div className="modal-body">
                   {modalType === 'view' && currentDirector && (
                        <>
                        <p><strong>Name:</strong> {currentDirector.name}</p>
                    <p><strong>Bio:</strong> {currentDirector.bio}</p>
                    
                </>
                       
                    )}
                    {(modalType === 'add' || modalType === 'edit') && (
                        <>
                            <div className="mb-3">
                                <label htmlFor="DirName" className="form-label">Name</label>
                                <input
                                    id="DirName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    value={currentDirector.name}
                            onChange={(e) => setcurrentDirector({ ...currentDirector, name: e.target.value })}
                                />
                            </div>
                            <div>
                            <textarea
    className="form-control"
    placeholder="Bio"
    value={currentDirector.bio}
    rows="7"
    onChange={(e) => setcurrentDirector({ ...currentDirector, bio: e.target.value })}
/>
</div>

                           
                        </>
                    )}
                    {modalType === 'delete' && (
                        <>
                            <p>Are you sure you want to delete <strong>{currentDirector.name}</strong>?</p>
                        </>
                    )}
                </div>
                <div className="modal-footer">
                    {(modalType === 'add' || modalType === 'edit') && (
                        <>
                            <button type="button" className="btn btn-success" onClick={handleSave}>Save</button>
                            <button type="button" className="btn btn-danger" onClick={() => setModalType(null)}>Cancel</button>
                        </>
                    )}
                    {modalType === 'delete' && (
                        <>
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Yes, Delete</button>
                            <button type="button" className="btn btn-success" onClick={() => setModalType(null)}>Cancel</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    </div>
)}
<style jsx>{`
  .container {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 250px;
    padding: 20px;
    width: 80%;
  }
  h2{
  font-weight: bold; /* Makes the headings bold */
  
    text-align: center;
    color: #4caf50;
  }
 
  .btn-sm {
    background-color: transparent !important;
    border: none; /* Optional: Remove border if needed */
}
  .error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
   
  }
   .success{
  color:green;
  text-align:center;
  margin-bottom: 10px;
  }
  tr:hover {
    background-color: #ddd;
  }

.table-bordered tr:nth-child(even) {
    background-color: #f2f2f2; /* Adjust the color as needed */
}
{
    background-color: #f2f2f2;
  }

 
  .btn-sm:hover,.icon-button:hover {
    color: #4caf50;
  }


  
  .pagination {
  display: inline-block;
}

.pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin: 0 2px;
  background-color: white;
  
}

.pagination button.active {
  background-color: #4CAF50;
  color: white;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
}

.pagination button:disabled {
  color: gray;
  cursor: not-allowed;
}
.btn-success{
background-color: #4CAF50;
}


`}</style> 
    </div>
    );
};

export default ManageDirector;
