import React, { useState, useEffect } from "react";
import axiosInstance from "../interceptor.js";
import endpoints from "../endpoint.js";
import { FaEye, FaEdit, FaTrashAlt } from 'react-icons/fa';

const ManageGenres = () => {
    const [genres, setGenres] = useState([]);
    const [currentGenres, setcurrentGenres] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState('');
    const [page, setPage] = useState(1); // Current page
    const [limit, setLimit] = useState(10); // Items per page
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [searchQuery, setSearchQuery] = useState(""); // Search query

    useEffect(() => {
        fetchGenres();
    }, [page, limit, searchQuery]);

    const fetchGenres = async () => {
        try {
            const response = await axiosInstance.post(endpoints.getGenres, { page, limit, search: searchQuery });

            if (response.data.success && Array.isArray(response.data.data)) {
                setGenres(response.data.data); // Update genres list
                setTotalPages(response.data.pagination.totalPages); // Update total pages (ensure backend provides this)
            } else {
                setSuccess(response.data.message);
                setTimeout(() => setSuccess(''), 4000);// Handle error message from backend
            }
        } catch (error) {
            console.log(error);
            setError("Failed to fetch Genres");
            setTimeout(() => setSuccess(''), 4000);
        }
    };
    const filteredGenres = genres.filter(genre =>
        genre.name.toLowerCase().includes(searchQuery.toLowerCase())
       
    );

    const createGenres = async () => {
        try {
            if (!currentGenres.name || currentGenres.name.trim() === '') {
                setError('Genre name is required.');
                setTimeout(() => setError(''), 4000);
                console.error('Validation Error: Genre name is missing.');
                return;
            }

            const payload = {
                name: currentGenres.name.trim(),
            };
            if (currentGenres.description && currentGenres.description.trim() !== '') {
                payload.description = currentGenres.description.trim();
            }

            const response = await axiosInstance.post(endpoints.createGenres, payload);

            console.log('Create Genre Response:', response.data);

            setModalType(null);
            fetchGenres();
            setSuccess("Successfully Added the Genre");
            setTimeout(() => {
                setSuccess('');
            }, 4000);

        } catch (error) {
            console.error('Error creating Genre:', error.response?.data || error.message);
            setError('Error creating Genre. Please try again.');
            setTimeout(() => setError(''), 4000);
        }
    };

    const updateGenres = async () => {
        try {
            console.log('Current Genre before Save (Update):', currentGenres); // Debugging

            if (!currentGenres.name || currentGenres.name.trim() === '') {
                setError('Genre name is required.');
                setTimeout(() => setError(''), 4000);
                console.error('Validation Error: Genre name is missing.');
                return;
            }

            const payload = {
                id: currentGenres.id,
                name: currentGenres.name.trim(),
            };

            if (currentGenres.description && currentGenres.description.trim() !== '') {
                payload.description = currentGenres.description.trim();
            }

            const response = await axiosInstance.post(endpoints.updateGenres, payload);

            console.log('Update Genre Response:', response.data);

            setModalType(null);
            fetchGenres();
            setSuccess("Successfully Updated the Genre");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error updating Genre:', error.response?.data || error.message);
            setError('Error updating Genre. Please try again.');
            setTimeout(() => setError(''), 4000);
        }
    };

    const handleSave = () => {
        if (modalType === 'add') {
            createGenres();
        } else if (modalType === 'edit') {
            updateGenres();
        }
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.post(endpoints.deleteGenres, { id: currentGenres.id });
            setModalType(null);
            setGenres([]);
            fetchGenres();
            setSuccess("Successfully Deleted the Genre");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error deleting Genre:', error);
            setError("Error deleting genre. Please try again.");
            setTimeout(() => setError(''), 4000);
        }
    };

   

    return (
        <div className="container">
            <h2>Manage Genres</h2>

            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}

            <div className="d-flex justify-content-between align-items-center mb-3">
    {/* Add Genre Button */}
    <button
        className="btn btn-success"
        style={{ marginBottom: '0px' }}
        onClick={() => {
            setcurrentGenres({ name: "", description: "" });
            setModalType('add');
        }}
    >
        Add Genre
    </button>

    {/* Search Input Field */}
    <div className="input-group col-4">
        <input
            type="text"
            className="form-control"
            placeholder="Search genres..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
        />
        <span className="input-group-text">
        <i className="fas fa-search"></i>
        </span>
    </div>
</div>

{/* 
            <button className="btn btn-success col-2" style={{ marginBottom: '20px' }} onClick={() => {
                setcurrentGenres({ name: "", description: "" });
                setModalType('add');
            }}>Add Genre</button>

           
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search genres..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div> */}

            <div className="card shadow-sm">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover align-middle">
                            <thead className="table-light">
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredGenres.length > 0 ? (
                                    filteredGenres.map((genre) => (
                                        <tr key={genre.id}>
                                            <td>{genre.name}</td>
                                            <td>{genre.description}</td>
                                            <td>
                                                <button className="btn btn-sm me-1" onClick={() => {
                                                    setcurrentGenres(genre);
                                                    setModalType('view');
                                                }}>
                                                    <i className="fas fa-eye"></i>
                                                </button>
                                                <button className="btn btn-sm me-1" onClick={() => {
                                                    setcurrentGenres(genre);
                                                    setModalType('edit');
                                                }}>
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                <button className="btn btn-sm" onClick={() => {
                                                    setcurrentGenres(genre);
                                                    setModalType('delete');
                                                }}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No Genre found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div
                className="pagination"
                style={{
                    display: "flex", // Use flexbox for alignment
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically if needed
                    marginTop: "20px", // Optional spacing from other elements
                }}
            >
                <button
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    disabled={page === 1}
                >
                    &laquo;
                </button>

                {Array.from({ length: totalPages }, (_, index) => {
                    const pageNumber = index + 1;
                    return (
                        <button
                            key={pageNumber}
                            onClick={() => setPage(pageNumber)}
                            className={page === pageNumber ? "active" : ""}
                        >
                            {pageNumber}
                        </button>
                    );
                })}

                <button
                    onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={page === totalPages}
                >
                    &raquo;
                </button>
            </div>

            {modalType && (
                <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalType === 'view' ? 'Genre Details' : modalType === 'add' ? 'Add New Album' : modalType === 'edit' ? 'Edit Genre' : 'Confirm Deletion'}
                                </h5>
                                <button type="button" className="btn-close" onClick={() => setModalType(false)}></button>
                            </div>
                            <div className="modal-body">
                                {modalType === 'view' && currentGenres && (
                                    <>
                                        <p><strong>Name:</strong> {currentGenres.name}</p>
                                        <p><strong>description:</strong> {currentGenres.description}</p>
                                       
                                    </>
                                )}
                                {(modalType === 'add' || modalType === 'edit') && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="GenreName" className="form-label">Name</label>
                                            <input
                                                id="GenreName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Name"
                                                value={currentGenres.name}
                                                onChange={(e) => setcurrentGenres({ ...currentGenres, name: e.target.value })}
                                            />
                                        </div>
                                        <div>
                                            <textarea
                                                className="form-control"
                                                placeholder="description"
                                                value={currentGenres.description}
                                                rows="7"
                                                onChange={(e) => setcurrentGenres({ ...currentGenres, description: e.target.value })}
                                            />
                                        </div>
                                    </>
                                )}
                                {modalType === 'delete' && (
                                    <>
                                        <p>Are you sure you want to delete <strong>{currentGenres.name}</strong>?</p>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                {(modalType === 'add' || modalType === 'edit') && (
                                    <>
                                        <button type="button" className="btn btn-success" onClick={handleSave}>Save</button>
                                        <button type="button" className="btn btn-danger" onClick={() => setModalType(null)}>Cancel</button>
                                    </>
                                )}
                                {modalType === 'delete' && (
                                    <>
                                        <button type="button" className="btn btn-danger" onClick={handleDelete}>Yes, Delete</button>
                                        <button type="button" className="btn btn-success" onClick={() => setModalType(null)}>Cancel</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <style jsx>{`
                .container {
                    display: flex;
                    flex-direction: column;
                    margin: 30px 0 0 250px;
                    padding: 20px;
                    width: 80%;
                }
.btn-sm {
    background-color: transparent !important;
    border: none; /* Optional: Remove border if needed */
}
                h2 {
                    font-weight: bold; /* Makes the headings bold */
                    text-align: center;
                    color: #4caf50;
                }
                .error {
                    color: red;
                    text-align: center;
                    margin-bottom: 10px;
                }
                .success {
                    color: green;
                    text-align: center;
                    margin-bottom: 10px;
                }
                tr:hover {
                    background-color: #ddd;
                }
                .table-bordered tr:nth-child(even) {
                    background-color: #f2f2f2; /* Adjust the color as needed */
                }
                .btn-sm:hover, .icon-button:hover {
                    color: #4caf50;
                }
                .pagination {
                    display: inline-block;
                }
                .pagination button {
                    color: black;
                    float: left;
                    padding: 8px 16px;
                    text-decoration: none;
                    border: none;
                    cursor: pointer;
                    margin: 0 2px;
                    background-color: white;
                }
                .pagination button.active {
                    background-color: #4CAF50;
                    color: white;
                }
                .pagination button:hover:not(.active) {
                    background-color: #ddd;
                }
                .pagination button:disabled {
                    color: gray;
                    cursor: not-allowed;
                }
                .btn-success {
                    background-color: #4CAF50;
                }
            `}</style>
        </div>
    );
};

export default ManageGenres;