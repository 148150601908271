import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaShieldAlt, FaUser, FaUsers,FaUserTie,FaGlobe, FaTags, FaMusic,FaFolderOpen } from "react-icons/fa";
import { FiAirplay, FiDatabase, FiGift, FiShoppingCart, FiUsers,FiFileText} from "react-icons/fi";
import { TbDashboard } from "react-icons/tb";
import { FaHotTubPerson, FaTableColumns } from "react-icons/fa6";
import reports from '../assets/bar-chart.png'
const Sidebar = () => {
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [activeLink, setActiveLink] = useState("/");
	// const [showDashboard, setShowDashboard] = useState(true);
	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible);
	};

	return (
		<div
			className="side-nav "
			style={{ marginTop: "-2%", backgroundColor: "#f5f5f5" }}
		>
			<div className="side-nav-inner ">
				<ul className="side-nav-menu scrollable mt-4" style={{marginLeft:"7%"}}>
				<li
    style={{ marginTop: "25%" }}
    className={`${activeLink === "/dashboard" ? "active_bg " : ""}`}
>
    <Link 
        to={"/dashboard"} 
        style={{ textDecoration: "none" }} // Apply textDecoration here
    >
        <span className="icon-holder">
            <TbDashboard className="dynamic-text-color" color="#4caf50" size={23} />
        </span>
        <span className="title">Dashboard</span>
    </Link>
</li>

					
					
					<li
    style={{ marginTop: "2%" }}
	
>
<Link to={"/manageArtists"} style={{ textDecoration: "none" }}>
    <span className="icon-holder">
        <FaUserTie className="dynamic-text-color" color="#4caf50" size={23} />
    </span>
    <span className="title">Manage Artists</span>
</Link>

<Link to={"/manageComposers"}
						style={{ textDecoration: "none" }}>
							<span className="icon-holder">
								<FaUserTie className="dynamic-text-color" color="#4caf50" size={23}/>
							</span>
							<span className="title">Manage Composer</span>
						</Link>

						<Link to={"/manageDirector"}
						style={{ textDecoration: "none" }}>
							<span className="icon-holder">
								<FaUserTie className="dynamic-text-color" color="#4caf50" size={23}/>
							</span>
							<span className="title">Manage Director</span>
						</Link>						

<Link to={"/managelanguages"}
	style={{ textDecoration: "none" }}>
        <span className="icon-holder">
            <FaGlobe className="dynamic-text-color" color="#4caf50" size={23}/>
        </span>
        <span className="title">Manage Language</span>
    </Link>
<Link to={"/manageGenres"}
	style={{ textDecoration: "none" }}>
        <span className="icon-holder">
            <FaTags className="dynamic-text-color" color="#4caf50" size={23}/>
        </span>
        <span className="title">Manage Genres</span>
    </Link>
<Link to={"/managealbums"}
style={{ textDecoration: "none" }}>
							<span className="icon-holder">
								<FaFolderOpen className="dynamic-text-color" color="#4caf50" size={23}/>
							</span>
							<span className="title">Manage Albums</span>
						</Link>
<Link to={"/managesongs"}
						style={{ textDecoration: "none" }}>
							<span className="icon-holder">
								<FaMusic className="dynamic-text-color" color="#4caf50" size={23}/>
							</span>
							<span className="title">Manage Songs</span>
						</Link>

</li>

					
					
				
				</ul>
			</div>
		</div>
	);
};

export default Sidebar;
