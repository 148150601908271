import React, { useState, useEffect } from "react";
import axiosInstance from "../interceptor.js";
import endpoints from "../endpoint.js";
import { RotatingLines } from 'react-loader-spinner';
import { compose } from "@reduxjs/toolkit";
import Multiselect from 'multiselect-react-dropdown';


const ManageSongs=()=>{
    const [songs, setSongs] = useState([]);
   
const [alertMessage, setAlertMessage] = useState('');
const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [currentSong,setCurrentSong]=useState(null);
    const [modalType, setModalType] = useState(null);
    const [error, setError] = useState("");
    const [imageModal, setImageModal] = useState(false);
    const [success, setSuccess] = useState('');
    const [albums, setAlbums] = useState([]);
    const [artists, setArtists] = useState([]);
    const [composers,setComposers]=useState([]);
    const [directors,setDirectors]=useState([]);
    const [genres, setGenres] = useState([]);
    const [language,setLanguages]=useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1); 
    const [limit, setLimit] = useState(10); // Items per page
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(()=>{
        fetchSongs();
    },[page,limit]);

    const fetchSongs = async () => {
        const payload = {
            page: page || 1,
            limit: limit || 10,
        };
        

        try {
            const response = await axiosInstance.post(endpoints.getSongs,payload);
            if (response.data && response.data.data) { // Adjusted to check for the correct structure
                setSongs(response.data.data);
                setTotalPages(response.data.pagination.totalPages); // Accessing the correct field
            } else {
                setSuccess("Data not available.");
                setTimeout(() => setSuccess(''), 4000);
            }
        } catch (error) {
            console.error('Error fetching songs:', error);
            setError("Failed to fetch songs.");
            setTimeout(() => setSuccess(''), 4000);
        }
    };
    const filtered = songs.filter(song =>
        song.title.toLowerCase().includes(searchQuery.toLowerCase())
       
    );
    const [searchTerm, setSearchTerm] = useState("");
    const filteredArtists = artists.filter((artist) =>
      artist.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredDirectors = directors.filter((director) =>
        director.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const filteredComposers = composers.filter((composer) =>
        composer.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    
    const fetchAllData = async () => {
        try {
            const artistsResponse = await axiosInstance.post(endpoints.getArtist);
            const albumsResponse = await axiosInstance.post(endpoints.getAlbums);
            const genresResponse = await axiosInstance.post(endpoints.getGenres);
            const languagesResponse = await axiosInstance.post(endpoints.getLang);
            const composerResponse=await axiosInstance.post(endpoints.getComp);
            const directorResponse=await axiosInstance.post(endpoints.getDir);
    
            if (
                artistsResponse.data.artists && 
                albumsResponse.data.albums && 
                genresResponse.data.data &&
                languagesResponse.data.data&&
                composerResponse.data.data&&
                directorResponse.data.data
            ) {
                // Filter languages to only include active ones
                const activeLanguages = languagesResponse.data.data.filter(language => language.status === 'active');
    
                setArtists(artistsResponse.data.artists);
                setAlbums(albumsResponse.data.albums);
                setGenres(genresResponse.data.data);
                setLanguages(activeLanguages); // Set only active languages
                setComposers(composerResponse.data.data);
                setDirectors(directorResponse.data.data);
                
                setCurrentSong({ 
                    title: "",
                    artists: [],
                    composers:[],
                    directors:[],
                    lang_id: "",
                    album_id: "",
                    genre_id: "",
                    release_date: "",
                    imagefile: null,
                    audiofile: null
                });
                setModalType('add');
                
            } else {
                setShowAlert(true); 
                setTimeout(() => setShowAlert(false), 4000); 
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError("Failed to fetch data.");
            setTimeout(() => setError(''), 4000);
        }
    };
    

    const fetchAllDataEdit = async (song) => {
        try {
            const artistsResponse = await axiosInstance.post(endpoints.getArtist);
            const albumsResponse = await axiosInstance.post(endpoints.getAlbums);
            const genresResponse = await axiosInstance.post(endpoints.getGenres);
            const languagesResponse = await axiosInstance.post(endpoints.getLang);
            const composerResponse=await axiosInstance.post(endpoints.getComp);
            const directorResponse=await axiosInstance.post(endpoints.getDir);
    
            if (
                artistsResponse.data.artists &&
                albumsResponse.data.albums &&
                genresResponse.data.data &&
                languagesResponse.data.data
                &&composerResponse.data.data
                &&directorResponse.data.data
            ) {
                const activeLanguages = languagesResponse.data.data.filter(language => language.status === 'active');
                setArtists(artistsResponse.data.artists);
                setAlbums(albumsResponse.data.albums);
                setGenres(genresResponse.data.data);
                setLanguages(activeLanguages);
                setComposers(composerResponse.data.data);
                setDirectors(directorResponse.data.data);
    
                // Set the current song for editing with all required fields, including the ID
                setCurrentSong({
                    id: song.id || "", // Ensure ID is set for updating the song
                    title: song.title || "",
                    artists: song.artists ? song.artists.map(artist => artist.id) : [],
                    composers:song.composers?song.composers.map(composer=>composer.id):[],
                    directors:song.directors?song.directors.map(director=>director.id):[],
                    lang_id: song.lang_id || "",
                    album_id: song.album_id || "",
                    genre_id: song.genre_id || "",
                    release_date: song.release_date || "",
                    imagefile: song.imagefile || null,
                    audiofile: song.audiofile || null,
                });
    
                setModalType('edit');
            } else {
                setShowAlert(true); 
                    setTimeout(() => setShowAlert(false), 4000); 
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError("Failed to fetch data.");
            setTimeout(() => setError(''), 4000);
        }
    };
    
    const fetchAllDataCopy = async (song) => {
        try {
            const artistsResponse = await axiosInstance.post(endpoints.getArtist);
            const albumsResponse = await axiosInstance.post(endpoints.getAlbums);
            const genresResponse = await axiosInstance.post(endpoints.getGenres);
            const languagesResponse = await axiosInstance.post(endpoints.getLang);
            const composerResponse=await axiosInstance.post(endpoints.getComp);
            const directorResponse=await axiosInstance.post(endpoints.getDir);
    
            if (
                artistsResponse.data.artists &&
                albumsResponse.data.albums &&
                genresResponse.data.data &&
                languagesResponse.data.data
                &&composerResponse.data.data
                &&directorResponse.data.data
            ) {
                const activeLanguages = languagesResponse.data.data.filter(language => language.status === 'active');
                setArtists(artistsResponse.data.artists);
                setAlbums(albumsResponse.data.albums);
                setGenres(genresResponse.data.data);
                setLanguages(activeLanguages);
                setComposers(composerResponse.data.data);
                setDirectors(directorResponse.data.data);
    
                // Set the current song details but remove the audiofile for replacement
                setCurrentSong({
                    title: song.title || "",
                    artists: song.artists ? song.artists.map(artist => artist.id) : [],
                    composers:song.composers?song.composers.map(composer=>composer.id):[],
                    directors:song.directors?song.directors.map(director=>director.id):[],
                    lang_id: song.lang_id || "",
                    album_id: song.album_id || "",
                    genre_id: song.genre_id || "",
                    release_date: song.release_date || "",
                    imagefile: song.imagefile || null, // Keep image if needed
                    audiofile: null, // Ensure user uploads a new audio file
                });
    
                setModalType('add'); // Open the add modal to create a new song
            } else {
                setShowAlert(true); 
                    setTimeout(() => setShowAlert(false), 4000); 
            }
        } catch (error) {
            console.error('Error fetching data for copy:', error);
            setError("Failed to fetch data.");
            setTimeout(() => setError(''), 4000);
        }
    };
    
    
    const handleCreate = async () => {
        try {
            if (
                !currentSong.title ||
                !currentSong.album_id ||
                !currentSong.genre_id ||
                !currentSong.artists.length || // Ensure at least one artist is selected
                !currentSong.lang_id ||
                !currentSong.composers.length ||
                !currentSong.directors.length
            ) {
                setError("Please fill all the fields");
                setTimeout(() => setError(''), 4000);
                return;
            }
    
            const formData = new FormData();
            formData.append('title', currentSong.title);
            formData.append('release_date', currentSong.release_date);
            formData.append('lang_id', currentSong.lang_id);
            formData.append('album_id', currentSong.album_id);
            formData.append('genre_id', currentSong.genre_id);
    
            // Fix: Send artist_ids as expected by the backend
            currentSong.artists.forEach(artistId => {
                formData.append('artist_ids', artistId);
            });
            currentSong.composers.forEach(CompId => {
                formData.append('composer_ids', CompId);
            });
            currentSong.directors.forEach(DirId => {
                formData.append('director_ids', DirId);
            });
    
            if (currentSong.audiofile) {
                formData.append('audiofile', currentSong.audiofile);
            }
    
            if (currentSong.imagefile) {
                formData.append('imagefile', currentSong.imagefile);
            }
    
            setLoading(true); // Start loader before making the request
    
            const response = await axiosInstance.post(endpoints.createSongs, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.success) {
                fetchSongs(); // Refresh the songs list
                setSuccess('Successfully added the song');
            } else {
                setError(response.data.message || 'Failed to add song');
            }
    
            setModalType(null);
            setTimeout(() => setSuccess(''), 4000);
        } catch (error) {
            console.error('Error adding song:', error.response?.data || error.message);
            setError('Error adding song. Please try again.');
            setTimeout(() => setError(''), 4000);
        } finally {
            setLoading(false); // Stop loader once the request is complete
        }
    };
    
 
    // const handleCreate = async () => {
    //     try {
    //         if (
    //             !currentSong.title || 
    //             !currentSong.album_id || 
    //             !currentSong.genre_id || 
    //             !currentSong.artists.length ||  // Ensure at least one artist is selected
    //             !currentSong.lang_id ||
    //             !currentSong.composers.length ||
    //             !currentSong.directors.length 
    //         ) {
    //             setError("Please fill all the fields");
    //             setTimeout(() => setError(''), 4000);
    //             return;
    //         }
    
    //         const formData = new FormData();
    //         formData.append('title', currentSong.title);
    //         formData.append('release_date', currentSong.release_date);
    //         formData.append('lang_id', currentSong.lang_id);
    //         formData.append('album_id', currentSong.album_id);
    //         formData.append('genre_id', currentSong.genre_id);
    
    //         // Fix: Send artist_ids as expected by the backend
            
    //         currentSong.artists.forEach(artistId => {
    //             formData.append('artist_ids', artistId);
    //         });
    //         currentSong.composers.forEach(CompId=>{
    //             formData.append('composer_ids', CompId);
    //         });
    //         currentSong.directors.forEach(DirId=>{
    //             formData.append('director_ids', DirId);
    //             });
    
    //         if (currentSong.audiofile) {
    //             formData.append('audiofile', currentSong.audiofile);
    //         }
    
    //         if (currentSong.imagefile) {
    //             formData.append('imagefile', currentSong.imagefile);
    //         }
    //         setLoading(true);
    
    //         const response = await axiosInstance.post(endpoints.createSongs, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });
    
    //         if (response.data.success) {
    //             fetchSongs(); // Refresh the songs list
    //             setSuccess('Successfully added the song');
    //         } else {
    //             setError(response.data.message || 'Failed to add song');
    //         }
    
    //         setModalType(null);
    //         setTimeout(() => setSuccess(''), 4000);
    //     } catch (error) {
    //         console.error('Error adding song:', error.response?.data || error.message);
    //         setError('Error adding song. Please try again.');
    //         setTimeout(() => setError(''), 4000);
    //     }
    // };
    const handleUpdate = async () => {
        try {
            if (
                !currentSong.title || 
                !currentSong.album_id || 
                !currentSong.genre_id || 
                !currentSong.artists.length ||  // Ensure at least one artist is selected
                !currentSong.lang_id ||
                !currentSong.composers.length ||
                !currentSong.directors.length 
                
            ) {
                setError("Please fill all the fields");
                setTimeout(() => setError(''), 4000);
                return;
            }
    
            const formData = new FormData();
            formData.append('id', currentSong.id);
            formData.append('title', currentSong.title);
            formData.append('release_date', currentSong.release_date);
            formData.append('lang_id', currentSong.lang_id);
            formData.append('album_id', currentSong.album_id);
            formData.append('genre_id', currentSong.genre_id);
    
      
            currentSong.artists.forEach(artistId => {
                formData.append('artist_ids', artistId);
            });
            currentSong.composers.forEach(composerId => {
                formData.append('composer_ids', composerId);
                });
                currentSong.directors.forEach(directorId => {
                    formData.append('director_ids', directorId);
                    });
    
            if (currentSong.audiofile) {
                formData.append('audiofile', currentSong.audiofile);
            }
            if (currentSong.imagefile) {
                formData.append('imagefile', currentSong.imagefile);
            }
    
            console.log("FormData contents:");
            for (let [key, value] of formData.entries()) {
                console.log(`${key}:`, value);
            }
    
        
            const response = await axiosInstance.post(endpoints.updateSongs, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
    
            console.log("Update response:", response.data);
    
            if (response.data.success) {
                await fetchSongs(); 
                setSuccess('Successfully updated the song.');
            } else {
                setError(response.data.message || 'Failed to update song');
            }
    
            // UI Updates
            setModalType(false);
            setTimeout(() => setSuccess(''), 10000);
        } catch (error) {
            console.error('Error updating song:', error.response?.data || error.message);
            setError('Error updating song. Please try again.');
            setTimeout(() => setError(''), 10000);
        }
    };
    
    
    
    const handleSave = () => {
        if (modalType === 'add') {
            handleCreate();
        } else if (modalType === 'edit') {
            handleUpdate();
        }
    }; 
    
    const handleDelete=async()=>{
        try {
            await axiosInstance.post(endpoints.deleteSongs, { id: currentSong.id });
            setModalType(null);
            setSongs([]);
            fetchSongs();
            setSuccess("Successfully Deleted the song");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error deleting song:', error);
            setError("Error deleting song. Please try again.");
            setTimeout(() => setError(''), 4000);
        }
    };

    return (
            <div className="container">
                <h2>Manage Songs</h2>
                {showErrorAlert && (
    <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>{alertMessage}</strong>
        <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowErrorAlert(false)}
          
        ></button>
    </div>
)}

                {showAlert && (
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>Please create albums, artists, language, and genres before creating a new song</strong>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}></button>
            </div>
        )}
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p> }
                <div className="d-flex justify-content-between align-items-center mb-3">
                <button className="btn btn-success col-2" style={{ marginBottom: '20px' }} onClick={fetchAllData}
                   
                >Add Song</button>

                 {/* Search Input Field */}
    <div className="input-group col-4">
        <input
            type="text"
            className="form-control"
            placeholder="Search Song..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
        />
        <span className="input-group-text">
        <i className="fas fa-search"></i>
        </span>
    </div>
</div>

<div className="card shadow-sm">
  <div className="card-body">
    <div className="table-responsive">
      <table className="table table-bordered table-hover align-middle">
        <thead className="table-light">
        <tr>
                <th>Title</th>
                <th>Released Date</th>
                <th>Language</th>
                <th>Album</th>
                <th>Genre</th>
                <th>Artist</th>
                <th>Composer</th>
                <th>Directors</th>
                <th>Song</th>
                <th>Image</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
        {filtered.length > 0 ? (
            filtered.map((song) => (
                <tr key={song.id}>
                        <td>{song.title}</td>
                        <td>{song.release_date || "Date not provided"}</td>
                        <td>{song.languages?.name || "Unknown Language"}</td>
                        <td>{song.albums?.title || "Unknown Album"}</td>
                        <td>{song.genres?.name || "Unknown Genre"}</td>
                        <td>{song.artists?.map(artist => artist.name).join(", ") || "Unknown Artist"}</td>
                        <td>{song.composers?.map(composer => composer.name).join(", ") || "Unknown composer"}</td>
                        <td>{song.directors?.map(director => director.name).join(", ") || "Unknown Director"}</td>


                        <td>
                            {song.songs_url ? (
                                <audio
                                controls
                                style={{ borderRadius: "4px", objectFit: "cover" }}
                                onPlay={() => setCurrentSong(song)}
                                onError={(e) => {
                                    console.error("Audio failed to load:", song.songs_url);
                                    e.target.onerror = null; // Prevent infinite loop
                                    
                                    // Show the alert message
                                    setAlertMessage("Audio file could not be played. It should be in .mp3 format.");
                                    setShowErrorAlert(true);
                                    setTimeout(() => setShowErrorAlert(false), 4000);  // Trigger alert display
                                }}
                            >
                                <source src={song.songs_url} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                               
                            ) : (
                                "No Audio Available"
                            )}
                        </td>

                        <td>
                            {song.image_url ? (
                                <img
                                    src={song.image_url}
                                    alt={song.name || "song Image"}
                                    width="100"
                                    height="100"
                                    style={{ borderRadius: "4px", objectFit: "cover" }}
                                    onClick={() => {
                                        setCurrentSong(song);
                                        setImageModal(true);
                                    }}
                                    
                                    onError={(e) => {
                                        console.error("Image failed to load:", song.image_url);
                                        e.target.onerror = null;
                                        // e.target.src = "https://via.placeholder.com/50";
                                    }}
                                />
                            ) : (
                                "No Image"
                            )}
                        </td>
        
            <td>
    {/* View Button */}
    <button className="btn btn-sm me-1" onClick={() => {
        setCurrentSong(song);
        setModalType('view');
    }}>
        <i className="fas fa-eye"></i>
    </button>

    {/* Edit Button */}
    <button onClick={() => fetchAllDataEdit(song)}
        className="btn btn-sm me-1">
        <i className="fas fa-edit"></i>
    </button>

    {/* Delete Button */}
    <button className="btn btn-sm me-1" onClick={() => {
        setCurrentSong(song);
        setModalType('delete');
    }}>
        <i className="fas fa-trash-alt"></i>
    </button>

 

<button onClick={() => fetchAllDataCopy(song)}
        className="btn btn-sm me-1">
        <i className="fas fa-copy"></i>
    </button>

</td>


                       
                    </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No Song found</td>
                            </tr>
                        )}
                    </tbody>

      </table>
    </div>
  </div>
</div>
    


<div
    className="pagination"
    style={{
        display: "flex", // Use flexbox for alignment
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically if needed
        marginTop: "20px", // Optional spacing from other elements
    }}
>
    <button
        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        disabled={page === 1}
    >
        &laquo;
    </button>

    {Array.from({ length: totalPages }, (_, index) => {
        const pageNumber = index + 1;
        return (
            <button
                key={pageNumber}
                onClick={() => setPage(pageNumber)}
                className={page === pageNumber ? "active" : ""}
            >
                {pageNumber}
            </button>
        );
    })}

    <button
        onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={page === totalPages}
    >
        &raquo;
    </button>
</div>

{modalType && (
    <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="modal-dialog modal-md">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {modalType === 'view' ? 'Song Details' : modalType === 'add' ? 'Add New Song' : modalType === 'edit' ? 'Edit Song' : 'Confirm Deletion'}
                    </h5>
                    <button type="button" className="btn-close" onClick={() => setModalType(false)}></button>
                </div>
                <div className="modal-body">
                    {modalType === 'view' && currentSong && (
                        <>
                        <p><strong>Title:</strong> {currentSong.title}</p>
                    <p><strong>Duration:</strong>{currentSong.duration}</p>
                    <p><strong>Released Date:</strong> {currentSong.release_date}</p>
                    <p>
<strong>Language:</strong>{currentSong.languages?.name}
</p>
                    <p>
<strong>Album:</strong> {currentSong.albums?.title}
</p>
<p>
<strong>Genre:</strong> {currentSong.genres?.name}
</p>
<p>
  <strong>Artist:</strong> {currentSong.artists.length > 0 ? currentSong.artists.map((artist) => artist.name).join(", ") : "No artist"}
</p>
<p>
  <strong>Director:</strong> {currentSong.directors.length > 0 ? currentSong.directors.map((director) => director.name).join(", ") : "No director"}
</p>
<p>
  <strong>Composer:</strong> {currentSong.composers.length > 0 ? currentSong.composers.map((composer) => composer.name).join(", ") : "No artist"}
</p>


                    <p>
<strong>Song:</strong> 
<br></br>
{currentSong.songs_url ? (
<audio controls>
<source src={currentSong.songs_url} type="audio/mpeg" />
Your browser does not support the audio element.
</audio>
) : (
"No Song URL"
)}
</p>
<p>
<strong>Image:</strong> 
<img src={currentSong.image_url} alt={currentSong.name} width="100" />
</p>

                   
                        </>
                    )}
                    {(modalType === 'add' || modalType === 'edit') && (
                        <>
                            <div className="mb-3">
                                <label htmlFor="artistName" className="form-label">Name</label>
                                <input
                                    id="songName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    value={currentSong.title}
                                    onChange={(e) => setCurrentSong({ ...currentSong, title: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="releaseDate" className="form-label">Released Date</label>
                                <input
                                    id="releaseDate"
                                    type="date"
                                    className="form-control"
                                    value={currentSong.release_date}
                                    onChange={(e) => setCurrentSong({ ...currentSong, release_date: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="langSelect" className="form-label">Language</label>
                                <select
                                    id="langSelect"
                                    className="form-select"
                                    value={currentSong.lang_id || ""}
                                    onChange={(e) => setCurrentSong({ ...currentSong, lang_id: e.target.value })}
                                >
                                    <option value="" disabled>Select Language</option>
                                    {language.length > 0 ? (
                                    language.map((lang) => (
                                        <option key={lang.id} value={lang.id}>
{lang.name}
                                        </option>
                                    ))
                                ) : (
<option disabled>No languages available</option>
)
                                    }
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="albumSelect" className="form-label">Album</label>
                                <select
                                    id="albumSelect"
                                    className="form-select"
                                    value={currentSong.album_id || ""}
                                    onChange={(e) => setCurrentSong({ ...currentSong, album_id: e.target.value })}
>
<option value="" disabled>Select Album</option>
{albums.length > 0 ? (
albums.map((album) => (
<option key={album.id} value={album.id}>
{album.title}
</option>
))
) : (
<option disabled>No albums available</option>
)}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="genreSelect" className="form-label">Genre</label>
                                <select
                                    id="genreSelect"
                                    className="form-select"
                                    value={currentSong.genre_id || ""}
onChange={(e) => setCurrentSong({ ...currentSong, genre_id: e.target.value })}
>
<option value="" disabled>Select Genre</option>
{genres.length > 0 ? (
genres.map((genre) => (
<option key={genre.id} value={genre.id}>
{genre.name}
</option>
))
) : (
<option disabled>No genres available</option>
)}

                                </select>
                            </div>



<div className="mb-3 relative">
  <label className="form-label">Artists</label>
  <Multiselect
    options={filteredArtists}
    selectedValues={filteredArtists.filter((artist) => currentSong.artists.includes(artist.id))}
    onSelect={(selectedList) => setCurrentSong((prev) => ({ ...prev, artists: selectedList.map(item => item.id) }))}
    onRemove={(selectedList) => setCurrentSong((prev) => ({ ...prev, artists: selectedList.map(item => item.id) }))}
    displayValue="name"
  />
</div>

<div className="mb-3 relative">
  <label className="form-label">Directors</label>
  <Multiselect
    options={filteredDirectors}
    selectedValues={filteredDirectors.filter((director) => currentSong.directors.includes(director.id))}
    onSelect={(selectedList) => setCurrentSong((prev) => ({ ...prev, directors: selectedList.map(item => item.id) }))}
    onRemove={(selectedList) => setCurrentSong((prev) => ({ ...prev, directors: selectedList.map(item => item.id) }))}
    displayValue="name"
  />
</div>

<div className="mb-3 relative">
  <label className="form-label">Composers</label>
  <Multiselect
    options={filteredComposers}
    selectedValues={filteredComposers.filter((composer) => currentSong.composers.includes(composer.id))}
    onSelect={(selectedList) => setCurrentSong((prev) => ({ ...prev, composers: selectedList.map(item => item.id) }))}
    onRemove={(selectedList) => setCurrentSong((prev) => ({ ...prev, composers: selectedList.map(item => item.id) }))}
    displayValue="name"
  />
</div>




                            <div className="mb-3">
  <label htmlFor="audiofile" className="form-label">Upload Audio File:</label>
  <input
    type="file"
    id="audiofile"
    className="form-control"
    accept="audio/*"
    onChange={(e) => setCurrentSong({ ...currentSong, audiofile: e.target.files[0] })}
  />
</div>
                            
                            <div className="mb-3">
                                <label htmlFor="songImage" className="form-label">Image</label>
                                <input
                                    id="songImage"
                                    type="file"
                                    className="form-control"
                                    accept="image/*"
                                    onChange={(e) => setCurrentSong({ ...currentSong, imagefile: e.target.files[0] })}
                                    />
                            </div>
                        </>
                    )}
                    {modalType === 'delete' && (
                        <>
                            <p>Are you sure you want to delete <strong>{currentSong.name}</strong>?</p>
                        </>
                    )}
                </div>
                <div className="modal-footer">
                {(modalType === 'add' || modalType === 'edit') && (
    <>
        <button 
            disabled={loading} 
            type="button" 
            className="btn btn-success" 
            onClick={handleSave}
        >
            {loading ? (
                <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving...
                </>
            ) : (
                "Save"
            )}
        </button>
        <button 
            type="button" 
            className="btn btn-danger" 
            onClick={() => setModalType(null)} 
            disabled={loading} // Disable cancel while loading
        >
            Cancel
        </button>
    </>
)}

                    {modalType === 'delete' && (
                        <>
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Yes, Delete</button>
                            <button type="button" className="btn btn-success" onClick={() => setModalType(null)}>Cancel</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    </div>
)} 

{imageModal && currentSong && (
    <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                
                    <h5 className="modal-title">{currentSong.title}</h5>
                    
                    <button type="button" className="btn-close" onClick={() => setImageModal(false)}></button>
                </div>
                <div className="modal-body text-center">
                    <img
                        src={currentSong.image_url}
                        alt={currentSong.title}
                        style={{ maxWidth: '100%', maxHeight: '50vh', objectFit: 'contain' }}
                    />
                </div>
            </div>
        </div>
    </div>
)}
 {loading && (
                                        <div className="loading-spinner-container" >
                                            <RotatingLines
                                                strokeColor="grey"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="70"
                                                visible={true}
                                            />
                                        </div>
                                    )}
<style jsx>{`
  .container {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 250px;
    padding: 20px;
    width: 80%;
  }
  h2{
  font-weight: bold; /* Makes the headings bold */
  
    text-align: center;
    color: #4caf50;
  }
 
  
  .error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
   
  }
   .success{
  color:green;
  text-align:center;
  margin-bottom: 10px;
  }
  tr:hover {
    background-color: #ddd;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

 
  .btn-sm:hover,.icon-button:hover {
    color: #4caf50;
  }


  
  .pagination {
  display: inline-block;
}

.pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin: 0 2px;
  background-color: white;
  
}

.pagination button.active {
  background-color: #4CAF50;
  color: white;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
}

.pagination button:disabled {
  color: gray;
  cursor: not-allowed;
}
.btn-success{
background-color: #4CAF50;
}
.btn-sm {
    background-color: transparent !important;
    border: none; /* Optional: Remove border if needed */
}
    



`}</style>
    
      

    
    
    
            </div>
    
        );


};
export default ManageSongs;