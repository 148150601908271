import React, { useState, useEffect } from "react";
import axiosInstance from "../interceptor.js";
import endpoints from "../endpoint.js";
import { FaEye, FaEdit, FaTrashAlt } from 'react-icons/fa';

const ManageArtists = () => {
    const [artists, setArtists] = useState([]);
    const [currentArtist, setCurrentArtist] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [error, setError] = useState("");
    const [imageModal, setImageModal] = useState(false);
     const [success, setSuccess] = useState('');
     const [page, setPage] = useState(1); // Current page
         const [limit, setLimit] = useState(10); // Items per page
         const [totalPages, setTotalPages] = useState(1); // Total pages
     const [searchQuery, setSearchQuery] = useState("");


    useEffect(() => {
        fetchArtists();
    }, [page, limit]);

    const fetchArtists = async () => {
        const payload = {
            page: page || 1,
            limit: limit || 10,
        };
    
        console.log("Payload being sent:", payload); // Debug payload
    
        try {
            const response = await axiosInstance.post(endpoints.getArtist, payload);

            if (response.data.artists) {
                setArtists(response.data.artists);
                setTotalPages(response.data.pagination.totalPages);
            } else {
                setSuccess("Data is not available");
                setTimeout(() => setSuccess(''), 4000);
            }
        } catch (error) {
            console.error("Error fetching artists:", error);
            setError("Failed to fetch artists.");
            setTimeout(() => setError(''), 4000);
        }
    };
    const filtered = artists.filter(artist =>
        artist.name.toLowerCase().includes(searchQuery.toLowerCase())
       
    );
    
    

    const handleCreate = async () => {
        try {
            const formData = new FormData();
            formData.append('name', currentArtist.name);
            formData.append('bio', currentArtist.bio);
    
            if (currentArtist.imagefile) {
                formData.append('imagefile', currentArtist.imagefile);
            }
    
            await axiosInstance.post(endpoints.createArtist, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
    
            setModalType(null);
            fetchArtists();
            setSuccess("Successfully Added the Artist");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error adding artist:', error);
            setError('Error adding artist. Please try again.');
            setTimeout(() => setError(''), 4000);
        }
    };
    
    const handleUpdate = async () => {
        try {
            const formData = new FormData();
            formData.append('id', currentArtist.id);
            formData.append('name', currentArtist.name);
            formData.append('bio', currentArtist.bio);
    
            if (currentArtist.imagefile) {
                formData.append('imagefile', currentArtist.imagefile);
            }
    
            await axiosInstance.post(endpoints.updateArtist, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
    
            setModalType(null);
            fetchArtists();
            setSuccess("Successfully Updated the Artist");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error updating artist:', error);
            setError('Error updating artist. Please try again.');
            setTimeout(() => setError(''), 4000);
        }
    };
    const handleSave = () => {
        if (modalType === 'add') {
            handleCreate();
        } else if (modalType === 'edit') {
            handleUpdate();
        }
    };     

    const handleDelete = async () => {
        try {
            await axiosInstance.post(endpoints.deleteArtist, { id: currentArtist.id });
            setModalType(null);
            setArtists([]);
            fetchArtists();
            setSuccess("Successfully Deleted the Artist");
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (error) {
            console.error('Error deleting artist:', error);
            setError("Error deleting artist. Please try again.");
            setTimeout(() => setError(''), 4000);
        }
    };

    return (
        <div className="container">
            <h2>Manage Artists</h2>

            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p> }
            <div className="d-flex justify-content-between align-items-center mb-3">
            <button className="btn btn-success col-2" style={{ marginBottom: '20px' }} onClick={() => {
                setCurrentArtist({ name: "", bio: "", imagefile: null });
                setModalType('add');
            }}>Add Artist</button>

             {/* Search Input Field */}
    <div className="input-group col-4">
        <input
            type="text"
            className="form-control"
            placeholder="Search Artist..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
        />
        <span className="input-group-text">
        <i className="fas fa-search"></i>
        </span>
    </div>
</div>

<div className="card shadow-sm">
  <div className="card-body">
    <div className="table-responsive">
      <table className="table table-bordered table-hover align-middle">
        <thead className="table-light">
        <tr>
                            <th>Name</th>
                            <th>Bio</th>
                            <th>Image</th>
                            <th>Actions</th>
                        </tr>
        </thead>
        <tbody>
        {filtered.length > 0 ? (
        filtered.map((artist) => (
                <tr key={artist.id}>
                                    <td>{artist.name}</td>
                                    <td>{artist.bio || "No Bio Available"}</td>
                                    <td>
                                        {artist.image_url ? (
                                            <img
                                                src={artist.image_url}
                                                alt={artist.name || "Artist Image"}
                                                width="100"
                                                height="100"
                                                style={{ borderRadius: "4px", objectFit: "cover" }}
                                                onClick={() => {
                                                    setCurrentArtist(artist);
                                                    setImageModal(true);
                                                }}
                                                onError={async (e) => {
                                                    console.error("Image failed to load:", artist.image_url);
                                                    e.target.onerror = null;
                                                    // e.target.src = "https://via.placeholder.com/50";

                                                    try {

                                                        const response = await axiosInstance.post(endpoints.refreshArtistImage, {
                                                            id: artist.id,
                                                        });
                                                        if (response.data.image_url) {
                                                            e.target.src = response.data.image_url;
                                                        }
                                                    } catch (error) {
                                                        console.error("Failed to refresh image URL:", error);
                                                    }
                                                }}
                                            />
                                        ) : (
                                            "No Image"
                                        )}
                                    </td>

                                    <td>
              <button className="btn btn-sm me-1" onClick={() =>  {
                                            setCurrentArtist(artist);
                                            setModalType('view');
                                        }}>
                <i className="fas fa-eye"></i>
              </button>
              <button className="btn btn-sm me-1" onClick={() =>{
                                            setCurrentArtist(artist);
                                            setModalType('edit');
                                        }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className="btn btn-sm " onClick={() => {
                                            setCurrentArtist(artist);
                                            setModalType('delete');
                                        }}>
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>

                                    
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No Artist found</td>
                            </tr>
                        )}
                    </tbody>

      </table>
    </div>
  </div>
</div>


            
            <div
    className="pagination"
    style={{
        display: "flex", // Use flexbox for alignment
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically if needed
        marginTop: "20px", // Optional spacing from other elements
    }}
>
    <button
        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        disabled={page === 1}
    >
        &laquo;
    </button>

    {Array.from({ length: totalPages }, (_, index) => {
        const pageNumber = index + 1;
        return (
            <button
                key={pageNumber}
                onClick={() => setPage(pageNumber)}
                className={page === pageNumber ? "active" : ""}
            >
                {pageNumber}
            </button>
        );
    })}

    <button
        onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={page === totalPages}
    >
        &raquo;
    </button>
</div>
{modalType && (
    <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="modal-dialog modal-md">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {modalType === 'view' ? 'Artist Details' : modalType === 'add' ? 'Add New Artist' : modalType === 'edit' ? 'Edit Artist' : 'Confirm Deletion'}
                    </h5>
                    <button type="button" className="btn-close" onClick={() => setModalType(false)}></button>
                </div>
                <div className="modal-body">
                    {modalType === 'view' && currentArtist && (
                        <>
                        <p><strong>Name:</strong> {currentArtist.name}</p>
                    <p><strong>Bio:</strong> {currentArtist.bio}</p>
                    <p><strong>Image:</strong> <img src={currentArtist.image_url} alt={currentArtist.name} width="100" /></p>
                   
                        </>
                    )}
                    {(modalType === 'add' || modalType === 'edit') && (
                        <>
                            <div className="mb-3">
                                <label htmlFor="artistName" className="form-label">Name</label>
                                <input
                                    id="artistName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    value={currentArtist.name}
                                    onChange={(e) => setCurrentArtist({ ...currentArtist, name: e.target.value })}
                                />
                            </div>
                            <div>
                            <textarea
    className="form-control"
    placeholder="Bio"
    value={currentArtist.bio}
    rows="7"
    onChange={(e) => setCurrentArtist({ ...currentArtist, bio: e.target.value })}
/>
</div>

                            
                            <div className="mb-3">
                                <label htmlFor="artistImage" className="form-label">Image</label>
                                <input
                                    id="artistImage"
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => setCurrentArtist({ ...currentArtist, imagefile: e.target.files[0] })}
                                    />
                            </div>
                        </>
                    )}
                    {modalType === 'delete' && (
                        <>
                            <p>Are you sure you want to delete <strong>{currentArtist.name}</strong>?</p>
                        </>
                    )}
                </div>
                <div className="modal-footer">
                    {(modalType === 'add' || modalType === 'edit') && (
                        <>
                            <button type="button" className="btn btn-success" onClick={handleSave}>Save</button>
                            <button type="button" className="btn btn-danger" onClick={() => setModalType(null)}>Cancel</button>
                        </>
                    )}
                    {modalType === 'delete' && (
                        <>
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Yes, Delete</button>
                            <button type="button" className="btn btn-success" onClick={() => setModalType(null)}>Cancel</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    </div>
)}         
{imageModal && currentArtist && (
    <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                
                    <h5 className="modal-title">{currentArtist.name}</h5>
                    <button type="button" className="btn-close" onClick={() => setImageModal(false)}></button>
                </div>
                <div className="modal-body text-center">
                    <img
                        src={currentArtist.image_url}
                        alt={currentArtist.name}
                        style={{ maxWidth: '100%', maxHeight: '50vh', objectFit: 'contain' }}
                    />
                </div>
            </div>
        </div>
    </div>
)}
           
<style jsx>{`
  .container {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 250px;
    padding: 20px;
    width: 80%;
  }
  h2{
  font-weight: bold; /* Makes the headings bold */
  
    text-align: center;
    color: #4caf50;
  }
 
  
  .error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
   
  }
   .success{
  color:green;
  text-align:center;
  margin-bottom: 10px;
  }
  tr:hover {
    background-color: #ddd;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

 
  .btn-sm:hover,.icon-button:hover {
    color: #4caf50;
  }


  
  .pagination {
  display: inline-block;
}

.pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin: 0 2px;
  background-color: white;
  
}
  .btn-sm {
    background-color: transparent !important;
    border: none; /* Optional: Remove border if needed */
}

.pagination button.active {
  background-color: #4CAF50;
  color: white;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
}

.pagination button:disabled {
  color: gray;
  cursor: not-allowed;
}
.btn-success{
background-color: #4CAF50;
}


`}</style>



        </div>

    );
};

export default ManageArtists;

