import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "http://localhost:3000", // Correct backend port
});

axiosInstance.interceptors.request.use(
  (config) => {
    console.log("Request Interceptor Triggered:", config);
    let token = localStorage.getItem("token");

    try {
      token = JSON.parse(token); // Parse only if it's JSON
    } catch (error) {
      console.warn("Token is not a JSON string:", token);
    }

    config.headers["Authorization"] = "Bearer " + token;
    return config;
  },
  (error) => {
    console.error("Request Interceptor Error:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log("Response Interceptor Triggered:", response);
    return response;
  },
  (error) => {
    console.error("Response Interceptor Error:", error.response || error);

    if (error.response?.status === 401) {
      // Prevent redirection on change password failure
      if (error.response?.data?.message === "Old password is incorrect") {
        return Promise.reject(error);
      }
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
