const urls = {
	// LOGIN API's
	login: process.env.REACT_APP_BASE_URL + "/login",
	logout:process.env.REACT_APP_BASE_URL + "/logout",
	reports_dashboard:process.env.REACT_APP_BASE_URL + "/reports/dashboard",

	getLang: process.env.REACT_APP_BASE_URL + "/language/get",
	createLang: process.env.REACT_APP_BASE_URL + "/language/create",
	updateLang: process.env.REACT_APP_BASE_URL + "/language/update",
	deleteLang: process.env.REACT_APP_BASE_URL + "/language/delete",

	getArtist: process.env.REACT_APP_BASE_URL + "/artists/get",
	createArtist: process.env.REACT_APP_BASE_URL + "/artists/create",
	updateArtist: process.env.REACT_APP_BASE_URL + "/artists/update",
	deleteArtist: process.env.REACT_APP_BASE_URL + "/artists/delete",

	getGenres: process.env.REACT_APP_BASE_URL + "/genres/get",
	createGenres: process.env.REACT_APP_BASE_URL + "/genres/create",
	updateGenres: process.env.REACT_APP_BASE_URL + "/genres/update",
	deleteGenres: process.env.REACT_APP_BASE_URL + "/genres/delete",

	getAlbums: process.env.REACT_APP_BASE_URL + "/albums/get",
	createAlbums: process.env.REACT_APP_BASE_URL + "/albums/create",
	updateAlbums: process.env.REACT_APP_BASE_URL + "/albums/update",
	deleteAlbums: process.env.REACT_APP_BASE_URL + "/albums/delete",

	getSongs: process.env.REACT_APP_BASE_URL + "/songs/get",
	createSongs: process.env.REACT_APP_BASE_URL + "/songs/create",
	updateSongs: process.env.REACT_APP_BASE_URL + "/songs/update",
	deleteSongs: process.env.REACT_APP_BASE_URL + "/songs/delete",

	getDir: process.env.REACT_APP_BASE_URL + "/directors/get",
	createDir: process.env.REACT_APP_BASE_URL + "/directors/create",
	updateDir: process.env.REACT_APP_BASE_URL + "/directors/update",
	deleteDir: process.env.REACT_APP_BASE_URL + "/directors/delete",

	getComp: process.env.REACT_APP_BASE_URL + "/composers/get",
	createComp: process.env.REACT_APP_BASE_URL + "/composers/create",
	updateComp: process.env.REACT_APP_BASE_URL + "/composers/update",
	deleteComp: process.env.REACT_APP_BASE_URL + "/composers/delete",

	change_password:process.env.REACT_APP_BASE_URL+"/change-password",
};

export default urls;
