import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "./Components/Layout";
import Login from "./Pages/Login";
import { useState, useEffect } from "react";
import ChangePassword from "./Pages/ChangePassword";
import Dashboard from "./Pages/Dashboard";
import ManageLanguage from "./Pages/Languages.js"; 
import ManageArtists from "./Pages/artists.js";
import ManageGenres from "./Pages/genres.js";
import ManageAlbums from "./Pages/albums.js";
import ManageSongs from "./Pages/songs.js";
import ManageDirector from "./Pages/directors.js";
import ManageComposers from "./Pages/composers.js";
// import ManageGenres from "./Pages/genres.js"
import "react-datepicker/dist/react-datepicker.css";
import "./Hooks/styles.css";
import ProtectedRoute from './MiddleWare/AuthMiddleWare';

import PageNotFound from "./Pages/PageNotFound.js";

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const checkUserToken = () => {
		const userToken = localStorage.getItem('token');
		if (!userToken || userToken === 'undefined') {
			setIsLoggedIn(false);
		} else {
			setIsLoggedIn(true);
		}
		console.log(userToken)
	}

	useEffect(() => {
		checkUserToken();
	}, []);

	const router = createBrowserRouter([
		{
			element: isLoggedIn ? <Navigate to="/login" replace /> : <Login />,
			path: "/"
		},
		{
			element: <Login />,
			path: "/login",
		},
		{
			path: "*",
			element: isLoggedIn ? <Navigate to="/login" replace /> : <Navigate to="/pagenotfound" replace />,
		},
		{
			element: <Layout />,
			children: [
				{
					path: "/dashboard",
					element: <ProtectedRoute><Dashboard /> </ProtectedRoute>,
				},
				{
					path: "/changepassword",
					element: <ProtectedRoute><ChangePassword /> </ProtectedRoute>,
				},
				{
					path: "/managealbums", 
					element: <ProtectedRoute><ManageAlbums /></ProtectedRoute>,
				},
				{
					path: "/managesongs", 
					element: <ProtectedRoute><ManageSongs /></ProtectedRoute>,
				},
				{
					path: "/managelanguages", 
					element: <ProtectedRoute><ManageLanguage /></ProtectedRoute>,
				},
				{
					path: "/manageartists", 
					element: <ProtectedRoute><ManageArtists /></ProtectedRoute>,
				},
				{
					path: "/managecomposers", 
					element: <ProtectedRoute><ManageComposers /></ProtectedRoute>,
				},
				{
					path: "/managedirector", 
					element: <ProtectedRoute><ManageDirector /></ProtectedRoute>,
				},
				{
					path: "/managegenres", 
					element: <ProtectedRoute><ManageGenres /></ProtectedRoute>,
				},
				{
					path: "/pagenotfound",
					element: <ProtectedRoute> <PageNotFound /> </ProtectedRoute>
				}
			],
		},
	]);

	return (
		<div>
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
