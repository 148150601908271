import React, { useState } from "react";
import axiosInstance from "../interceptor";
import urls from "../endpoint";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const changePass = async (e) => {
        e.preventDefault();
        setSuccess("");
        setError("");

        try {
            const response = await axiosInstance.post(urls.change_password, {
                oldPassword,
                newPassword
            });

            if (response.data.code === 200) {
                setSuccess("Password changed successfully!");
                setTimeout(() => setSuccess(""), 4000);
            } else {
                setError(response.data.message || "Failed to change password.");
                setTimeout(() => setError(""), 4000);
            }
        } catch (error) {
            if (error.response?.status === 401) {
                setError("Old password is incorrect. Please try again.");
                setTimeout(() => setError(""), 4000);
            } else {
                setError(error.response?.data?.message || "Something went wrong.");
                setTimeout(() => setError(""), 4000);
            }
        }
    };

    const togglePasswordVisibility = (field) => {
        if (field === "oldPassword") {
            setShowOldPassword((prevShowPassword) => !prevShowPassword);
        } else if (field === "newPassword") {
            setShowNewPassword((prevShowPassword) => !prevShowPassword);
        }
    };

    return (
        <div className="container">
            <h2>Change Password</h2>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}

            <div className="card shadow-sm">
                <div className="card-body">
                    <form onSubmit={changePass}>
                        <div className="form-group">
                            <label htmlFor="oldPassword">Enter Old Password</label>
                            <div className="input-group">
                                <input
                                    type={showOldPassword ? "text" : "password"}
                                    className={`form-control ${formErrors.oldPassword ? "is-invalid" : ""}`}
                                    placeholder="Enter old password"
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    name="oldPassword"
                                />
                                <span
                                    className="input-group-text"
                                    onClick={() => togglePasswordVisibility("oldPassword")}
                                    style={{ cursor: "pointer" }}
                                >
                                    {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                            </div>
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor="newPassword">Enter New Password</label>
                            <div className="input-group">
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    className={`form-control ${formErrors.newPassword ? "is-invalid" : ""}`}
                                    placeholder="Enter new password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    name="newPassword"
                                />
                                <span
                                    className="input-group-text"
                                    onClick={() => togglePasswordVisibility("newPassword")}
                                    style={{ cursor: "pointer" }}
                                >
                                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="btn btn-success"
                            style={{
                                marginBottom: "20px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>

            <style jsx>{`
                .container {
                    display: flex;
                    flex-direction: column;
                    margin: 30px 0 0 250px;
                    padding: 20px;
                    width: 80%;
                }
                h2 {
                    font-weight: bold;
                    text-align: center;
                    color: #4caf50;
                }
                .error {
                    color: red;
                    text-align: center;
                    margin-bottom: 10px;
                }
                .success {
                    color: green;
                    text-align: center;
                    margin-bottom: 10px;
                }
					.card{
					// padding:90px;
					margin:90px;
					}
            `}</style>
        </div>
    );
};

export default ChangePassword;
